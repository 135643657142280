import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { Observable } from 'rxjs';
import * as myGlobals from "@shared/global_var";

@Injectable({
    providedIn: 'root'
})
export class RecruiterService {

    constructor(
        public http: HttpClient,
        private _Global: GlobalfunctionService
    ) { }

    // step 1 get department list
    getRecruiterSite(): Observable<any> {
        return this.http.get(`${myGlobals._apiRecruiter}site/list`, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getRecruiterStudy(siteId: number): Observable<any> {
        return this.http.get(`${myGlobals._apiRecruiter}study/list/${siteId}`, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getSlotList(requestBody: any): Observable<any> {
        return this.http.post(`${myGlobals._apiRecruiter}slot/list`, requestBody, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getSlotFilterList(requestBody: any): Observable<any> {
        return this.http.post(`${myGlobals._apiRecruiter}slot/getWithinRange`, requestBody, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    deleteSlot(id: number, route: string): Observable<any> {
        return this.http.delete(`${myGlobals._apiRecruiter}${route}${id}`, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    addSlot(requestBody: any): Observable<any> {
        return this.http.post(`${myGlobals._apiRecruiter}slot/create-new`, requestBody, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    editSlot(requestBody: any): Observable<any> {
        return this.http.put(`${myGlobals._apiRecruiter}slot/update`, requestBody, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    copySlot(requestBody: any): Observable<any> {
        return this.http.post(`${myGlobals._apiRecruiter}slot/duplicateslot-create`, requestBody, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    validateSlot(requestBody: any): Observable<any> {
        return this.http.post(`${myGlobals._apiRecruiter}slot/verify/single`, requestBody, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getAvailableSlots(requestBody: any): Observable<any> {
        return this.http.post(`${myGlobals._apiRecruiter}slot/getAvailableSlots`, requestBody, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getSlotConflictData(requestBody: any): Observable<any> {
        return this.http.post(`${myGlobals._apiRecruiter}slot/overlapping-details`, requestBody, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getSlotRequest(requestBody: any): Observable<any> {
        return this.http.post(`${myGlobals._apiRecruiter}slot/requested-list`, requestBody, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    slotUpdateRequest(requestBody: any): Observable<any> {
        return this.http.put(`${myGlobals._apiRecruiter}slot/requested-slot-update`, requestBody, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getAuditTrailData(requestBody: any): Observable<any> {
        return this.http.post(`${myGlobals._apiRecruiter}slot/get-site-audit-trail`, requestBody, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getSiteBasedOnRole(): Observable<any> {
        return this.http.post(`${myGlobals._apiRecruiter}slot/recruiter-site-details`, {}, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getBookedPatient(requestBody: any): Observable<any> {
        return this.http.post(`${myGlobals._apiRecruiter}slot/booked-patient-list`, requestBody, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    reschedulePatientSlot(requestBody: any): Observable<any> {
        return this.http.post(`${myGlobals._apiRecruiter}slot/update-pi-slot`, requestBody, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    deleteRequestSlot(requestBody: any): Observable<any> {
        return this.http.post(`${myGlobals._apiRecruiter}slotDeleteRequest/create`, requestBody, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getRequestDeleteList(requestBody: any): Observable<any> {
        return this.http.post(`${myGlobals._apiRecruiter}slotDeleteRequest/list`, requestBody, { headers: this._Global.httpOptions }) as Observable<any>;
    }

    getRequestDeleteCount(requestBody: any): Observable<any> {
        return this.http.post(`${myGlobals._apiRecruiter}slotDeleteRequest/getDeleteRequestCount`, requestBody, { headers: this._Global.httpOptions }) as Observable<any>;
    }

}