import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormArray, FormGroup, Validators, FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar } from "@angular/material";
import { RecruiterAssignmentService } from "@sharedservices/BackServices/ComTrak/RecruiterAssignment/RecruiterAssignment.service";
import { IconService } from "@sharedservices/BackServices/ComTrak/Icon/icon.service";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { CloseRecruiterAssignmentPromptComponent } from "../close-recruiter-assignment-prompt/close-recruiter-assignment-prompt.component";
import { take, takeUntil } from "rxjs/operators";
import { GlobalfunctionService } from "@sharedservices/FrontServices/globalfunction.service";
import { IndividualConfig, ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import { AppInsightsService } from "@markpieszak/ng-application-insights";
import { ShowPoolStatusesInfoComponent } from "../show-pool-statuses-info/show-pool-statuses-info.component";
import * as moment from 'moment-timezone'; 
@Component({
  selector: "app-recruiter-assignment",
  templateUrl: "./recruiter-assignment.component.html",
  styleUrls: ["./recruiter-assignment.component.css"],
})
export class RecruiterAssignmentComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(public dialogRef: MatDialogRef<RecruiterAssignmentComponent>, private fb: FormBuilder, public icon: IconService, @Inject(MAT_DIALOG_DATA) public PoolData: any, private _recruiterAssignmentService: RecruiterAssignmentService, public snackBar: MatSnackBar, public dialog: MatDialog, public _Global: GlobalfunctionService, private toastService: ToastrService, private appInsightsService: AppInsightsService) {}
  @ViewChild("scrollContainer") scrollContainer!: ElementRef;
  dropdownSettingsState: IDropdownSettings = {
    singleSelection: true,
    idField: "id",
    textField: "name",
    allowSearchFilter: true,
    enableCheckAll: false,
    maxHeight: 120,
  };

  mainForm: FormGroup;
  availableCountsOrigional: number = 0;
  assignedCountsOrigional: number = 0;
  totalReleasedCount: number = 0;
  isStatsAvailable: boolean = false;
  recruiterArr = [];
  recruiterArrOrigional = [];
  previousAssignments = [];
  previousAssignmentsOrigional = [];

  UserTotalCounts: number = 0;
  totalCounts = "-";
  availableCounts = "-";
  assignedCounts = "-";
  poolTitle = "Loading...";
  businessImpact = "-";
  studydifficulty = "-";
  patientScore = "-";

  businessImpactColor = "red";
  studydifficultyColor = "green";
  patientScoreColor = "orange";

  studyDifficultyTooltip = "1-3 = Easy to Enroll, 4-7 = Moderately Difficult to Enroll, 8-10 = Difficult to Enrol";
  selectedItem: any;
  dropdownSettings = {};
  focusedControlIndex: number | null = null;
  focusedControlName: string | null = null;
  lastRefreshTime: string = "";
  lastRefreshBy: string = "";

  private destroy$ = new Subject<void>();
  @ViewChild('falseClick') falseClick: ElementRef<HTMLElement>;
  toastOptions: Partial<IndividualConfig> = {
    timeOut: 3000,
    positionClass: "toast-top-right",
  };
  searchText: string = "";
  originalControls: any[] = [];
  isNOPChanged:boolean=false;
  ngOnInit() {
    this.appInsightsService.trackPageView("PageViewed", "/recruiter-assignment", {
      info: "page viewed at: " + new Date().toString(),
    });
    var _this = this;
    this.buildForm();

    let list = [];

    this._recruiterAssignmentService.getRecruiterList(this.PoolData.id);
    this._recruiterAssignmentService.recruiterList.pipe(take(1)).subscribe((_res: any) => {
      if (_res.success) {
        let res = _res.data;
        if (res.length > 0) {
          res.forEach((x, i) => {
            let newRecruiter = {
              id: x.recruiterId,
              name: `${x.firstName} ${x.lastName}`,
              patientsAssigned: x.patientsAssigned,
              patientsWorked: x.patientsAssigned,
            };
            list.push(newRecruiter);
          });
          this.recruiterArr = list;
          this.recruiterArrOrigional = JSON.parse(JSON.stringify(this.recruiterArr));
        }
        this._recruiterAssignmentService.GetPoolStats(this.PoolData.id);
      } else {
        this.toastService.error("Error while fetching data.", "Error", this.toastOptions);
      }
    });

    this._recruiterAssignmentService.poolStats.pipe(take(1)).subscribe((res: any) => {
      if (res.success) {
        _this.poolTitle = res.data.poolTitle;
        _this.lastRefreshBy = res.data.lastRefreshedBy;
        //_this.lastRefreshTime = res.data.lastRefreshedAt;
        _this.lastRefreshTime = moment.utc(res.data.lastRefreshedAt).tz('America/Chicago').format('MM/DD/YYYY [at] h:mm a')+" (CST)"
        _this.totalCounts = res.data.totalPatients;
        _this.availableCounts = res.data.availableCount;
        _this.assignedCounts = res.data.assignedPatients;
        _this.assignedCountsOrigional = parseInt(res.data.assignedPatients);
        _this.availableCountsOrigional = parseInt(res.data.availableCount);
        if (res.data.recruiterList.length > 0) {
          _this.previousAssignments = res.data.recruiterList.map((x) => ({
            ...x,
            name: x.firstName + " " + x.lastName,
            isReleaseAll: false,
          }));

          _this.previousAssignments.sort((a, b) => a.firstName.trim().localeCompare(b.firstName.trim()));
          _this.addPreviousAssignments(_this.previousAssignments);
          this.originalControls = this.assigns().controls.slice();
          _this.previousAssignmentsOrigional = JSON.parse(JSON.stringify(_this.previousAssignments));
          let existingRecruiterIds = _this.previousAssignments.map((x) => x.recruiterId);

          if (_this.recruiterArr.length > 0) {
            _this.recruiterArr = _this.recruiterArr.filter((x) => !existingRecruiterIds.includes(x.id));
          }
        }
        _this.isStatsAvailable = true;
      } else {
        this.toastService.error("Error while fetching pool data.", "Error", this.toastOptions);
        this.dialogRef.close("no");
      }
      //this.ShowPoolStatusesInfo();
    });

    _this._recruiterAssignmentService.recruiterStatsData.subscribe((res: any) => {
      this.assigns().at(res.index).get("patientsAssigned").setValue(res.data.patientsAssigedCount);
      this.assigns().at(res.index).get("patientsWorked").setValue(res.data.patientsWorkedCount);
    });

    this._recruiterAssignmentService.saveRecruiterAssignmentResponce.pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      if (res.statusCode == 500) {
        if (res.validationErrors.length > 0) {
          this.toastService.error("Error while saving data.", "Error", this.toastOptions);
        } else {
          this.toastService.error(res.validationErrors.join(" , "), "Error", this.toastOptions);
        }
        return;
      }
      if (res.success) {
        if (res.patientsAssigned > 0 && res.patientsInBookingStatus == 0) {
          this.toastService.success(res.patientsAssigned + " patients assigned successfully.", "Success", this.toastOptions);
          this.dialogRef.close("yes");
        } else if (res.patientsAssigned > 0 && res.patientsInBookingStatus > 0) {
          this.toastService.success(res.patientsAssigned + " patients assigned. " + res.patientsInBookingStatus + " are in booking status.", "Success", this.toastOptions);
          this.dialogRef.close("yes");
        } else if (res.patientsAssigned == 0 && res.patientsInBookingStatus > 0) {
          this.toastService.error("Patients are not assigned. " + res.patientsInBookingStatus + " are in booking status.", "Error", this.toastOptions);
        } else if (res.patientsAssigned == 0 && res.patientsInBookingStatus == 0) {
          this.toastService.error("No patients assigned.", "Error", this.toastOptions);
        } else {
          this.toastService.success("Patients Assigned.", "Success", this.toastOptions);
          //this.toastService.success("Partially patients assigned due to booking status.", "Success", this.toastOptions);
          this.dialogRef.close("yes");
        }
      }
    });

    this.assigns().valueChanges.subscribe((res) => {
      _this.UserTotalCounts = res.reduce((total, obj) => {
        let numberOfPatients = obj.numberOfPatients;
        if (numberOfPatients == "" || numberOfPatients == null || numberOfPatients == undefined) {
          numberOfPatients = 0;
        }
        return total + parseInt(numberOfPatients);
      }, 0);

      let diff = (_this.availableCountsOrigional + _this.totalReleasedCount - _this.UserTotalCounts).toString();
      _this.assignedCounts = (_this.assignedCountsOrigional - _this.totalReleasedCount + _this.UserTotalCounts).toString();

      if (parseInt(_this.assignedCounts) > parseInt(_this.totalCounts)) {
        _this.assignedCounts = _this.totalCounts;
      }

      if (parseInt(diff) < 0) {
        _this.availableCounts = "0";
      } else {
        _this.availableCounts = diff;
      }
      if (_this.UserTotalCounts > this.availableCountsOrigional) {
        //this.mainForm.setErrors({ numberOfPatientsExceed: true });
      } else {
        // this.mainForm.setErrors({ numberOfPatientsExceed: false });
      }
      _this.isNOPChanged=res.filter((x) => x.numberOfPatients).length == 0?false:true;
    });

    this._recruiterAssignmentService.ReleaseAllResponce.pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      if (res.success) {
        // make isReleaseAll=true and patientsAssigned=0 of res.recruiterId in _this.previousAssignments
        let index = _this.previousAssignments.findIndex((x) => x.recruiterId == res.recruiterId);
        //_this.previousAssignments[index].isReleaseAll = true;

        //update isReleaseAll of assign controls
        _this.assigns().controls.forEach((x, i) => {
          if (x.get("recruiterId").value == res.recruiterId) {
            x.get("isReleaseAll").setValue(true);
            x.get("patientsAssigned").setValue(res.patientsInBookingStatus);
          }
        });
        //update patientsAssigned of previousAssignments

        //_this.previousAssignments[index].patientsAssigned = 0;
        //it is becouse in some scenerios all patients wouldn't be released,which are in booking status, so we need to update patientsAssigned with patientsInBookingStatus
        _this.previousAssignments[index].patientsAssigned = res.patientsInBookingStatus;

        //add the releasedAll recruter to recruiterArr ,if no patient is in booking status
        if (res.patientsInBookingStatus == 0) {
          //no need to add recruiter to recruiterArr becouse now we give No of Patient textbox to exitsting recruiters according to CR 6576
          /*let recIndex = _this.recruiterArrOrigional.findIndex((x) => x.id == res.recruiterId);
          if (recIndex != -1) {
            _this.recruiterArr.push(_this.recruiterArrOrigional[recIndex]);
            _this.recruiterArr.sort((a, b) => a.name.trim().localeCompare(b.name.trim()));
          }*/
        }

        //update availableCounts,assignedCounts,totalReleasedCount
        // _this.totalReleasedCount = _this.totalReleasedCount + res.noOfPatients;
        // _this.availableCounts = (parseInt(_this.availableCounts) + res.noOfPatients).toString();
        // _this.assignedCounts = (parseInt(_this.assignedCounts) - res.noOfPatients).toString();

        //--------new logic of above 3 lines regarding to booking status
        _this.totalReleasedCount = _this.totalReleasedCount + res.patientsReleased;
        _this.availableCounts = (parseInt(_this.availableCounts) + res.patientsReleased).toString();
        _this.assignedCounts = (parseInt(_this.assignedCounts) - res.patientsReleased).toString();

        // update Validators.max of all numberOfPatients  of assign controls
        _this.assigns().controls.forEach((x, i) => {
          let validationArr = [Validators.min(1), Validators.max(_this.availableCountsOrigional + _this.totalReleasedCount)];
          if (!x.get("isPreviousAssignment").value) {
            validationArr.push(Validators.required);
          }

          x.get("numberOfPatients").setValidators(validationArr);

          x.get("numberOfPatients").updateValueAndValidity();
        });

        //this.openSnackbar("Patients released.", "success");
        if (res.patientsReleased > 0 && res.patientsInBookingStatus == 0) {
          this.toastService.success(res.patientsReleased + " patients released successfully.", "Success", this.toastOptions);
        } else if (res.patientsReleased > 0 && res.patientsInBookingStatus > 0) {
          this.toastService.success(res.patientsReleased + " patients released. " + res.patientsInBookingStatus + " are in booking status.", "Success", this.toastOptions);
        } else if (res.patientsReleased == 0 && res.patientsInBookingStatus > 0) {
          this.toastService.error("Patients are not released. " + res.patientsInBookingStatus + " are in booking status.", "Error", this.toastOptions);
        } else if (res.patientsReleased == 0 && res.patientsInBookingStatus == 0) {
          this.toastService.error("No patients released.", "Error", this.toastOptions);
        } else {
          this.toastService.success("Patients released.", "Success", this.toastOptions);
          //this.toastService.success("Partially patients assigned due to booking status.", "Success", this.toastOptions);
        }
      }
      if (res.statusCode == 500) {
        if (res.validationErrors && res.validationErrors.length > 0) {
          this.toastService.error(res.validationErrors.join(" , "), "Error", this.toastOptions);
        } else {
          this.toastService.error(res.message, "Error", this.toastOptions);
        }
      }
    });
  
  }

  buildForm() {
    this.mainForm = this.fb.group({
      assigns: this.fb.array([]),
    });
  }

  onKeyPress(event: KeyboardEvent) {
    const charCode = event.charCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }

  newAssign(recruiterId = null, patientsAssigned = "", patientsWorked = "", numberOfPatients = "", recruiterName = "", isPreviousAssignment = false): FormGroup {
    return this.fb.group({
      recruiterId: [recruiterId, [Validators.required]],
      recruiterName: [recruiterName],
      patientsAssigned: [patientsAssigned, [Validators.required]],
      patientsWorked: [patientsWorked, [Validators.required]],
      numberOfPatients: [numberOfPatients, [Validators.required, Validators.min(1), Validators.max(this.availableCountsOrigional + this.totalReleasedCount)]],
      isPreviousAssignment: [isPreviousAssignment],
      isReleaseAll: [false],
      recruiterDropDownValue:new FormControl([])
    });
  }

  assigns(): FormArray {
    return this.mainForm.get("assigns") as FormArray;
  }

  addAssign() {
    this.assigns().push(this.newAssign());
    this.originalControls = this.assigns().controls.slice();
  }

  //add Previous Assignments list In Assigns of mainForm
  addPreviousAssignments(previousAssignments) {
    previousAssignments.forEach((x) => {
      this.assigns().push(this.newAssign(x.recruiterId, x.patientsAssigned, x.patientsWorked, "", x.name, true));
    });
    //remove required validator from numberOfPatients
    this.assigns().controls.forEach((x, i) => {
      x.get("numberOfPatients").setValidators([Validators.min(1), Validators.max(this.availableCountsOrigional + this.totalReleasedCount)]);
      x.get("numberOfPatients").updateValueAndValidity();
    });
  }

  deleteAssign(assignIndex) {
    let recruiterId = this.assigns().at(assignIndex).get("recruiterId").value;
    let index = this.recruiterArrOrigional.findIndex((x) => x.id == recruiterId);
    if (index != -1) {
      this.recruiterArr.push(this.recruiterArrOrigional[index]);
      this.recruiterArr.sort((a, b) => a.name.trim().localeCompare(b.name.trim()));
    }

    this.assigns().removeAt(assignIndex);
    this.originalControls = this.assigns().controls.slice();
  }
  get f() {
    return this.mainForm.controls;
  }
  // anyNumberOfPatientsHasValue(): boolean {
  //   return this.assigns().controls.some(control => {
  //     const numberOfPatients = control.get('numberOfPatients')?.value;
  //     return numberOfPatients && numberOfPatients > 0;
  //   });
  // }

  AddNewAssign() {
    this.addAssign();
    this.scrollToBottom();
    //this.userScrolled=false;
    //this.scrollToBottomIfNeeded();
    //this.scrollToBottom();
  }
  onNoClick(): void {
    const dialogRef = this.dialog
      .open(CloseRecruiterAssignmentPromptComponent, {
        panelClass: "dailogwidth",
        disableClose: true,
        data: {},
      })
      .afterClosed()
      .subscribe((res) => {
        if (res === "yes") {
          if (this.totalReleasedCount > 0) {
            this.dialogRef.close("yes");
          } else {
            this.dialogRef.close("no");
          }
        } else {
        }
      });
  }
  submit() {
    let data = this.mainForm.value.assigns.filter((x) => x.numberOfPatients);
    if (data.length == 0) {
      this.toastService.warning("Please update or assign patients to recuiter .", "", this.toastOptions);
      return;
    }

    this._recruiterAssignmentService.SaveRecruiterAssignment(this.PoolData.id, data);
  }

  onRecruiterSelect(event, assignIndex) {
   
    setTimeout(() => {
      Array.from(document.getElementsByClassName("selected-item")).forEach((element) => {
        element.classList.remove("selected-item");
      });
    }, 1);
    this.assigns().at(assignIndex).get("recruiterId").setValue(event.id);
    this.assigns().at(assignIndex).get("patientsAssigned").setValue(0);
    this.assigns().at(assignIndex).get("patientsWorked").setValue(0);
    this.assigns().at(assignIndex).get("recruiterName").setValue(event.name);
    this.triggerFalseClick();
    //remove selected recruiter from recruiterArr
    let index = this.recruiterArr.findIndex((x) => x.id == event.id);
    this.recruiterArr.splice(index, 1);
    //---use following function to get recruiter stats from server
    //this._recruiterAssignmentService.GetRecruiterStats(this.PoolData.id, event.id, assignIndex);
  }

  // TotalCountValidate(AssignIndex) {
  //   let totalCount = 25;
  //   let assignCount = this.assigns().at(AssignIndex).get("numberOfPatients").value;
  //   if (assignCount > parseInt(this.totalCounts)) {
  //     this.mainForm.setErrors({ numberOfPatientsExceed: true });
  //     this.assigns().at(AssignIndex).get("numberOfPatients").setErrors({ numberOfPatientsExceed: true });
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  onRecruiterDeSelect(event, assignIndex) {
    let index = this.recruiterArrOrigional.findIndex((x) => x.id == event.id);
    if (index != -1) {
      this.recruiterArr.push(this.recruiterArrOrigional[index]);
      this.recruiterArr.sort((a, b) => a.name.trim().localeCompare(b.name.trim()));
    }
    this.assigns().at(assignIndex).get("recruiterId").setValue(null);
    this.assigns().at(assignIndex).get("patientsAssigned").setValue(0);
    this.assigns().at(assignIndex).get("patientsWorked").setValue(0);
  }

  releaseAll(recruiterId, noOfPatients) {
    this._recruiterAssignmentService.releaseAllPatients(this.PoolData.id, recruiterId, noOfPatients);
  }

  openSnackbar(message: string, action?: string) {
    var snackBarType = action == "success" ? "success" : "danger";
    this.snackBar.open(message, undefined, {
      duration: 3000,
      panelClass: snackBarType,
    });
  }

  onFocus(index: number, controlName: string) {
    this.focusedControlIndex = index;
    this.focusedControlName = controlName;
  }

  onBlur() {
    this.userScrolled = false;
    this.focusedControlIndex = null;
    this.focusedControlName = null;
  }

  shouldShowError(index: number, controlName: string): boolean {
    //this.scrollToBottom();
    let result = this.focusedControlIndex === index && this.UserTotalCounts > this.availableCountsOrigional + this.totalReleasedCount;
    if (result) {
      //this.scrollToBottomIfNeeded();
    }
    return result;

    // return this.focusedControlIndex === index && this.focusedControlName === controlName &&
    //        this.UserTotalCounts > (this.availableCountsOrigional + this.totalReleasedCount);
  }

  shouldRedBorder(): boolean {
    return this.UserTotalCounts > this.availableCountsOrigional + this.totalReleasedCount;
  }
  @ViewChild("tableWrapper") tableWrapper: ElementRef;

  private userScrolled: boolean = false;

  ngAfterViewInit() {
    // this.tableWrapper.nativeElement.addEventListener('scroll', () => {
    //   const element = this.tableWrapper.nativeElement;
    //   if (element.scrollTop + element.clientHeight >= element.scrollHeight - 1) {
    //     console.log('scrolled condition true');
    //     this.userScrolled = false;
    //   } else {
    //     console.log('scrolled condition false');
    //     this.userScrolled = true;
    //   }
    // });
  }

  scrollToBottomIfNeeded() {
    // if (!this.userScrolled) {
    //   setTimeout(() => {
    //     const element = this.tableWrapper.nativeElement;
    //     //element.scrollTop = element.scrollHeight;
    //   }, 100);
    // }
  }
  scrollToBottom(): void {
    try {
      setTimeout(() => {
        this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight + 66;
      }, 100);
    } catch (err) {}
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  origionalCotrols = [];
  filterRecruiters() {
    //filter previousAssignments on base of searchText
    this.sortByColumn = "name";
    this.sortByOrder = "asc";
    //this.previousAssignments =   this.previousAssignmentsOrigional.filter((x) => x.name.toLowerCase().includes(this.searchText.toLowerCase()));
    //this.previousAssignments.sort((a, b) => a.firstName.trim().localeCompare(b.firstName.trim()));
    //filter assign controls on base of searchText
    // if (this.searchText == "") {
    //   this.assigns().controls = JSON.parse(JSON.stringify(this.origionalCotrols));
    // }
    //origionalCotrols only assigned to assign controls when searchText is empty
    // if (this.origionalCotrols.length == 0 && this.searchText.length==1) {
    //   this.origionalCotrols = JSON.parse(JSON.stringify(this.assigns().controls));
    // }

    // this.origionalCotrols = JSON.parse(JSON.stringify(this.assigns().controls));
    /* this.assigns().controls = this.assigns().controls.filter((x) => x.get("recruiterName").value.toLowerCase().includes(this.searchText.toLowerCase()));
    this.assigns().controls.sort((a, b) => a.get("recruiterName").value.trim().localeCompare(b.get("recruiterName").value.trim()));
    if (this.searchText ) {
    }
*/

    if (this.searchText) {
      this.assigns().controls = this.originalControls.filter((x) => x.get("recruiterName").value.toLowerCase().includes(this.searchText.toLowerCase()));
    } else {
      // Restore the original controls
      this.assigns().controls = this.originalControls.slice();
    }
    setTimeout(() => {
      Array.from(document.getElementsByClassName("selected-item")).forEach((element) => {
        element.classList.remove("selected-item");
      });
    }, 1);
    //this.assigns().controls.map(x=>console.log(x.value))

    //return this.recruiterArr.filter((x) => x.name.toLowerCase().includes(searchText.toLowerCase()));
  }

  sortByColumn = "name";
  sortByOrder = "asc";
  //handle asc|desc sorting by name, patientsAssigned, patientsWorked
  sortPreviousAssignment(columnName) {
    if (this.sortByColumn == columnName) {
      this.sortByOrder = this.sortByOrder == "asc" ? "desc" : "asc";
    } else {
      this.sortByColumn = columnName;
      this.sortByOrder = "asc";
    }
    if (columnName == "name") {
      //this.previousAssignments.sort((a, b) => (this.sortByOrder == "asc" ? a.firstName.trim().localeCompare(b.firstName.trim()) : b.firstName.trim().localeCompare(a.firstName.trim())));
      this.assigns().controls.sort((a, b) => (this.sortByOrder == "asc" ? a.get("recruiterName").value.trim().split(" ")[0].localeCompare(b.get("recruiterName").value.trim().split(" ")[0]) : b.get("recruiterName").value.trim().split(" ")[0].localeCompare(a.get("recruiterName").value.trim().split(" ")[0])));
    } else if (columnName == "patientsAssigned") {
      // this.previousAssignments.sort((a, b) => (this.sortByOrder == "asc" ? a.patientsAssigned - b.patientsAssigned : b.patientsAssigned - a.patientsAssigned));
      this.assigns().controls.sort((a, b) => (this.sortByOrder == "asc" ? a.get("patientsAssigned").value - b.get("patientsAssigned").value : b.get("patientsAssigned").value - a.get("patientsAssigned").value));
    } else if (columnName == "patientsWorked") {
      //this.previousAssignments.sort((a, b) => (this.sortByOrder == "asc" ? a.patientsWorked - b.patientsWorked : b.patientsWorked - a.patientsWorked));
      this.assigns().controls.sort((a, b) => (this.sortByOrder == "asc" ? a.get("patientsWorked").value - b.get("patientsWorked").value : b.get("patientsWorked").value - a.get("patientsWorked").value));
    }
  }
  ShowPoolStatusesInfo(): void {
    const dialogRef = this.dialog
      .open(ShowPoolStatusesInfoComponent, {
        panelClass: "dailogwidth",
        disableClose: false,
        data: {},
      })
      .afterClosed()
      .subscribe((res) => {
        // if (res === "yes") {
        //   if (this.totalReleasedCount > 0) {
        //     this.dialogRef.close("yes");
        //   } else {
        //     this.dialogRef.close("no");
        //   }
        // } else {
        // }
      });
    }
  //sort ascending order to assign controls by name
  sortAssignControls() {
    this.assigns().controls.sort((a, b) => {
      return a.get("recruiterName").value.trim().localeCompare(b.get("recruiterName").value.trim());
    });
  }
  //sort descending order to assign controls by name
  sortAssignControlsDesc() {
    this.assigns().controls.sort((a, b) => {
      return b.get("recruiterName").value.trim().localeCompare(a.get("recruiterName").value.trim());
    });
  }

  triggerFalseClick() {
      let el: HTMLElement = this.falseClick.nativeElement;
      el.click();
  }
  OnDropdownClick(index){
    let totalCount =this.assigns().controls.length
    let diffCount = totalCount - index;
    if (diffCount<=3){
      this.scrollToBottom();
    }
  }
}
